

/* media 14000 */

@media (max-width:1400px) {

    .container{
      max-width: 1300px;
    }
    .navbar-brand {
      width: 110px !important;
      padding: 1px;
    }
    
    .navbar{
      background-color: #fff !important;
      outline: none;
      border: none;
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      transition: 0.4s ease-in-out;
    
    
    }
    
    
    }
    
    /* media 1400 end */
    
    
    /* media 1300 end */
    
    @media (max-width:1300px){
      
      .navbar .navbar-nav > li > a {
        color: var(--black-color);
        font-size: 14px !important;
        padding: 30px 10px !important;
        letter-spacing: 0.3px;
        transition: 0.4s ease-in-out;
        font-weight: 500;
      }
    
      .left_topbar a{
        margin: 0px 5px;
        color: #fff !important;
        transition: .4s ease-in-out;
        letter-spacing: .2px;
        z-index: -1;
        font-size: 13px !important;
      }
    
      
    .navbar_num_xl h5{
      font-size: 15px !important;
      text-transform: capitalize;
      font-weight: 700;
      color: var(--secondry-color);
      margin-bottom: 2px !important;
    }
    
    .navbar_num_xl .bx-envelope-open{
      font-size: 20px !important;
    }
    
    .navbar_num_xl .flex-grow-1 a{
      display: block !important;
      color: var(--primary-color);
      font-weight: 500;
      font-size: 14px !important;
    }
    
    .navbar_num_xl .bx-envelope-open{
      background-color: var(--primary-color);
      color: var(--white-color) !important;
      width: 42px !important;
      height: 42px !important;
      line-height: 42px !important;
      text-align: center;
      border-radius: 100px;
      font-size: 30px;
      position: relative;
      font-weight: 400;
    }
    
    .navbar_num_xl .bx-envelope-open:after {
      content:"";
      display:block;
      width: 42px !important;
      height: 42px !important;
      border-radius: 50%;
      border: 3px solid var(--primary-color);
      position:absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
      animation: pulse 2s ease 0s infinite;
    }
    
    
    .navbar-brand {
      width: 135px !important;
      padding: 1px;
    }
    
      }
      
      /* media 1300 end */
    
    
    
    /* media 991px s */
    @media (max-width:991px) {
    
      .why_us_cnt_img_box_m2{
        margin-bottom: 50px !important;
      }
    
      .topbar_section{
      display: none !important;
      }
    
      
    .top_bar_sec::before {
      content: "";
      position: absolute;
      background: var(--white-color);
      left: 0;
      top: 0;
      width: 100%;
      height: 4px !important;
    }
    
    .top_bar_sec::after{
      content: "";
      position: absolute;
      background: var(--primary-color) !important;
      left: 20% !important;
      right: 20% !important;
      top: 0;
      height: 4px;
    }
    
        .navbar_num_xl{
            display: none !important;
        }
    
        .about_head_title h1:before {
            content: "";
            border-top: solid 3px var(--secondry-color);
            width: 10%;
            height: 2px;
            position: absolute;
            top: 45%;
            z-index: 1;
            right: 26% !important;
          }
        
          
        .about_head_title h1:after {
            content: "";
            border-top: solid 3px var(--primary-color);
            width: 7%;
            height: 2px;
            position: absolute;
            top: 25%;
            z-index: 1;
            right: 26% !important;
          }
    
          .phone_view_icons_div{
            display: block !important;
            margin-top: 10px !important;
          }
    
    }
    /* media 991px end */
    
    
    /* 767 media */
    @media (max-width:767px) {
      .slider_section2{
        display: block !important;
     }
     .navbar-brand img{
      padding: 5px 0px;
     }
     .slider_section{
      display: none !important;
   }
      .card__bx{
        width: 100% !important;
        height: 450px !important;
      }
    
      .about_img_main_div{
        margin-bottom: 30px !important;
      }
    
        .container{
            padding: 0px 30px !important;
            }
    
            .about_overlay_box img {
                width: 150px !important;
                background-color: var(--secondry-color);
                padding: 6px;
                animation: mover 1s infinite alternate;
                border-radius: 0px !important;
            }
            
            
    
            
    .about_head_title h1{
        text-transform: capitalize;
        font-size: 36px;
        font-weight: 800;
        position: relative;
        /* display: inline-block;
        padding: 0px 30px; */
        margin-left: 0px !important;
    }
    
    .products_head_title h1{
        margin-left: 0px !important;
    }
    
       
    .about_head_title h1:before {
        content: "";
        border-top: solid 3px var(--secondry-color);
        width: 10%;
        height: 2px;
        position: absolute;
        top: 45%;
        z-index: 1;
        right: 30%;
        opacity: 0;
      }
    
      
    .about_head_title h1:after {
        content: "";
        border-top: solid 3px var(--primary-color);
        width: 7%;
        height: 2px;
        position: absolute;
        top: 25%;
        z-index: 1;
        right: 30%;
        opacity: 0;
      }
    
      .products_head_title h1:before{
        opacity: 0 !important;
      }
    
      
      .products_head_title h1:after{
        opacity: 0 !important;
      }
    
      .copyright_section {
        background-color: var(--primary-color);
        /* padding-bottom: 45px !important; */
    }
    
    .slider_section .carousel-item img{
        height: 500px !important;
        object-fit: cover !important;
    }
    
    }
    
    /* 767 media */