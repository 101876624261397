

* {
    margin: 0;
    padding: 0;
    box-shadow: none;
    box-sizing: border-box;
}



:root {
    --primary-color: #2e2e2e;
    --secondry-color: #d00701;
    --third-color: #db0707cc;
    --four-color: #d00701;
    --five-color: #b3bbe5;
    --white-color: #fff;
    --black-color: #000;
    --black-color2: #141615;
    --black-color3: #202020;
    --black-color4: #212529;
    --lightgray-color: #e7e6e6;
    --gray-color: #a1a1a1;
    --gray-color2: #a5a4a4;
    --gray-color3: #e7e7e7;
    --facebook-color: #3b5998;
    --instagram-color: #962fbf;
    --youtube-color: #c4302b;
    --background-color: #fafafa;
  }
  

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Josefin Sans', sans-serif;
}

img{
    max-width: 100% !important;
}

a{
	text-decoration: none;
	
}

a:focus {
	outline: none;
	text-decoration: none;
}

p{
    margin-bottom: 5px !important;
}

/* why css start */

 /*  */
 .whychoose_us_sec2 {
      background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
     margin-bottom: 5px;
    border: 5px solid #fff;
}



.whu_us_ovrlay_bg2 {
  background-color: var(--background-color);
    padding-top: 2px;
    padding-bottom: 40px;
    border: 5px solid #fff;
}

.whyus_hd_ti2 {
    padding-bottom: 20px;
    text-align: center;
}

.whyus_hd_ti2 h3 {
    color: var(--primary-black);
    font-weight: bold;
    font-size: 30px;
}

.why_us_txt_div_m2 {
    margin-bottom: 20px;
}

.why_us_txt_div_m2 h4 {
    text-transform: capitalize;
    font-size: 23px;
    font-weight: 700;
    padding-bottom: 3px;
    color: var(--primary-color);
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}


.why_us_txt_div_m2 p {
    font-size: 16px;
    line-height: 25px;
    font-weight: 600;
    color: #252525;
}

.whu_us_rd_btn2 {
    margin-top: 10px;
    margin-bottom: 20px;
}

.whu_us_rd_btn2 a {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 20px 10px 20px;
    border-radius: 100px;
    font-size: 15px;
    transition: .4s ease-in-out;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    }

    100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
}

.whu_us_rd_btn2 a:hover {
    background-color: #212121;
    transition: .4s ease-in-out;
    color: #fff;
}

.why_us_cnt_img_box_m2 {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: relative;
   border-radius: 100%;
}

.why_us_cnt_img_box_m2 img {
    text-align: center;
    margin: auto;
    display: block;
   border-radius: 100%;

}

.why_us_cnt_img_box_m2 video{
    text-align: center;
    margin: auto;
    display: block;
   border-radius: 100%;

}

.overly_whyus_img_dd2 {
    position: absolute;
    bottom: -10%;
    left: -5%;
    background-color: var(--gray-color3);
    padding: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
       border-radius: 100%;
}

.overly_whyus_img_dd2 img {
   width: 150px;
   height: 150px;
       border-radius: 100%;
       background-color: var(--background-color);
       padding: 10px;
       animation: mover 1s infinite  alternate;
    }

    .overly_whyus_img_dd2 video {
        width: 150px;
        height: 150px;
            border-radius: 100%;
            background-color: var(--background-color);
            padding: 10px;
            animation: mover 1s infinite  alternate;
         }
    
    @keyframes mover {
        0% { transform: translateY(0); }
        100% { transform: translateY(-10px); }
    }

    .overlay_qty{
        position: relative;
    }

    .overly_qty{
        position: absolute;
        top: 50%;
        right: -10%;
    }

    .overlay_qty .bx-envelope-open{
        background-color: var(--primary-color);
        color: var(--white-color) !important;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 100px;
        font-size: 20px;
        position: relative;
        font-weight: 400;
      }
      
      
      .overlay_qty .bx-envelope-open:after {
        content:"";
        display:block;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        border: 3px solid var(--secondry-color);
        position:absolute;
       top: 0;
       left: 0;
       right: 0;
       bottom: 0;
        animation: pulse 2s ease 0s infinite;
      }
      
      @keyframes pulse {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        80% {
            opacity: 0;
            transform: scale(2);
        }
        100% {
            opacity: 0;
            transform: scale(3);
        }
      }
    

.exprinec_txt_div2 {
    position: absolute;
    bottom: -11%;
    right: 0;
}

.exprinec_txt_div2 h5 {
    font-weight: bold;
    text-transform: capitalize;
       font-size: 18px
}

/*  */
/* why css start */
/* why us css */
.why_choose_us_section{
    padding-top: 20px;
        padding-bottom: 30px;
        background-color: var(--background-color);
        margin-bottom: 30px;
}

.why_choose_txt h4{
    font-size: 25px;
    font-weight: 600;
    text-transform: capitalize;
}

.why_choose_txt{
    margin-bottom: 20px;
}

.why_choose_txt p b{
    color: var(--secondry-color);
    font-weight: 500;
    font-size: 15px;
}

.why_choose_us_div_flx{
    margin-bottom: 10px;
}

.why_choose_us_div_flx .flex-shrink-0 i{
    background-color: var(--secondry-color);
    color: var(--white-color);
    width: 60px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    border-radius: 100px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    
}

.why_choose_us_div_flx{
    margin-top: 10px;
}

.why_us_div_txt_div p{
       font-size: 14px;
        color: var(--black-color3);
        line-height: 26px;
        letter-spacing: .5px;
        font-weight: 400 !important;
        margin-bottom: 7px !important;
}

.why_us_div_txt_div h5{
    color: var(--black-color);
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 500;
}

.certifcat_btn_div{
    margin-top: 20px;
}

.certifcat_btn_div a{
    background-color: var(--primary-color);
    padding: 10px 20px 10px 20px;
    color: var(--white-color);
    border-radius: 100px;
    font-size: 14px;
    letter-spacing: .4px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: .5s ease-in-out;
}

.certifcat_btn_div a:hover{
    background-color: var(--black-color3);
}

.why_us_img_div{
    text-align: center;
    position: relative;
}

.why_us_img_div img{
    margin: auto;
    text-align: center;
    display: block;
}

.why_overlay_div{
position: absolute;
bottom: -30%;
left: 0;
}

.why_overlay_div img{
    width: 300px;
}

.why_overlay_div2 {
    position: absolute;
    top: -25%;
    right: 25%;
}

.why_overlay_div2 img {
    width: 200px;
}

/* end */

/* about us css start */

.about_us_section{
    padding: 30px 0;
    position: relative;
}

.sec_img_overlay{
    position: absolute;
    right: 17%;
    bottom: 7%;
    z-index: -99999;
    opacity: .6;
}

.sec_img_overlay2{
    position: absolute;
    right: 0;
    bottom: 6%;
    z-index: -99999;
    opacity: .6;
}

.sec_img_overlay2 img{
    width: 250px;
}

.about_head_title{
    text-align: center;
    align-items: center;
    margin-bottom: 20px;
}

.about_content_div h1{
    text-transform: capitalize;
    font-size: 36px;
    font-weight: 800;
    position: relative;
    /* display: inline-block;
    padding: 0px 30px; */
    color: var(--primary-color);
    margin-bottom: 0px;
}

/* 
.about_head_title h1:before {
    content: "";
    border-top: solid 3px var(--secondry-color);
    width: 10%;
    height: 2px;
    position: absolute;
    top: 45%;
    z-index: 1;
    right: 30%;
  }

  
.about_head_title h1:after {
    content: "";
    border-top: solid 3px var(--primary-color);
    width: 7%;
    height: 2px;
    position: absolute;
    top: 25%;
    z-index: 1;
    right: 30%;
  } */


.about_head_title h1 span{
    color: var(--primary-color);
	background-image: -webkit-linear-gradient(92deg,  #141615, #47143d, #47143d, #141615, #141615);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

.about_head_title h1 img{
    width: 60px;
    text-align: center;
    align-items: center;
    margin-top: -12px;
    animation: mover 1s infinite  alternate;
}

@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

.about_head_title p{
    text-align: center;
    font-weight: 500;
}

.about_head_title h2{
    color: var(--primary-color);
    font-size: 22px;
     font-weight: 700;
    text-transform: uppercase;
}

.about_head_title h2 span{
    color: var(--secondry-color);
}

.about_img_main_div{
    position: relative;
    transition: .5s ease-in-out;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  
}

.about_vd_div{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding:10px 10px 3px 10px !important;
    background-color: var(--white-color);

}

.about_img_box{
    position: relative;
    transition: .5s ease-in-out;
    text-align: center;
    width: 100%;
}

.about_img_box img{
    transition: .5s ease-in-out;
    text-align: center;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.about_overlay_box{
    position: absolute;
    top: 50%;
    left: 30%;
}

.about_overlay_box img{
    width: 200px;
    background-color: var(--secondry-color);
    padding: 6px;
    animation: mover 1s infinite  alternate;
    border-radius: 0px !important;
}

.about_content_div h3{
    text-transform: capitalize;
    font-size: 23px;
    line-height: 30px;
    font-weight: 800;
    color: var(--black-color);
    line-height: 33px;
    margin-bottom: 5px !important;
}

.about_content_div h4{
    text-transform: capitalize;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    color: var(--primary-color); 
   
}

.img_about_div {
    margin-bottom: 30px;
}

.nst_img_div_ab{
    margin-bottom: 20px;
}

.img_about_div  a:nth-child(2){
   margin: 0px 20px;
}

.img_about_div img{
    filter: invert(100%);
    width: 50px;
}

.about_content_div p{
    font-size: 16px;
    line-height: 29px;
    font-weight: 500;
    padding: 0px 0px;
}
.about_btn_div {
    margin-top: 10px;
}
.about_btn_div img{
    width: 30px;
    animation: mover 1s infinite  alternate;
}

.about_btn_div{
    margin-top: 20px;
}
.about_btn_div a{
    text-transform: capitalize;
    position: relative;
    font-weight: 800;
    font-size: 16px;
}
.about_btn_div a{
    text-transform: capitalize;
    color: var(--white-color);
    padding: 12px 30px 12px 30px;
    background-color: var(--black-color4);
    border: 2px solid var(--white-color);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.about_btn_div a:hover{

    background-color: var(--primary-color);
}


.about_content_div  ul{
    list-style: none;
    padding: 0px 2px;
}


.about_content_div li{
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
}

.about_content_div b{
    color: var(--primary-color);
}


/* 
.about_btn_div a:before {
    content: "";
    border-top: solid 3px var(--secondry-color);
    width: 80%;
    height: 2px;
    position: absolute;
    top: 50%;
    z-index: 1;
    right: -100%;
  }

  
.about_btn_div a:after {
    content: "";
    border-top: solid 3px var(--primary-color);
    width: 50%;
    height: 2px;
    position: absolute;
    top: 10%;
    z-index: 1;
    right: -100%;
  } */


/* about us css end */

/* manuf css */
.manufact_sec{
    padding-top: 40px;
    padding-bottom: 50px;
    background-color: var(--background-color);
}


.manufact_head_title h1{
    text-transform: capitalize;
    font-size: 36px;
    font-weight: 800;
    position: relative;
   text-align: center;
}


.manufact_head_title h1 span{
    color: var(--primary-color);
	background-image: -webkit-linear-gradient(92deg,  #141615, #47143d, #47143d, #141615, #141615);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: hue 10s infinite linear;
}

@-webkit-keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}



/* end manuf css */



/* making css */
.making_section{
    background-color: var(--background-color);
    padding: 20px 0px;
}

.making_main_txt_div{
    text-align: center;
}

.making_main_txt_div h3{
    text-transform: capitalize;
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 7px;
    color: var(--primary-color);
}

.making_main_txt_div h3 span{
    color: var(--secondry-color);
    background-image: -webkit-linear-gradient(92deg,  #141615, #47143d, #47143d, #141615, #141615);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: hue 10s infinite linear;
}

.making_main_txt_div p{
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
}

.making_main_txt_div h3 img{
    width: 60px;
    text-align: center;
    align-items: center;
    margin-top: -12px;
    animation: mover 1s infinite  alternate;
}
/* making css end */

/* recycle div */

.recycle_div_icon{
    padding: 50px 0px;
}




.recycle_head_title{
  text-align: center;
    align-items: center;
    margin-bottom: 40px;
}

.recycle_head_title h4{
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 500;
    position: relative;
    font-family: 'Rubik Maps', system-ui;
    letter-spacing: 1px;
    text-align: center;
    color: var(--primary-color);
}

.recycle_head_title h4 span{
    color: var(--secondry-color);
}

.recycle_head_title h4 img{
    width: 60px;
    text-align: center;
    align-items: center;
    margin-top: -12px;
    animation: mover 1s infinite  alternate;
}

.recycle_icon_div{
    margin-bottom: 20px;
    text-align: center;
}

.recycle_img_div {
    text-align: center;
}

.recycle_img_div2 img{
    animation: mover 1s infinite  alternate;
}

.recycle_img_div img{
    text-align: center;
    margin: auto;
    width: 90px;
}

.recycle_icon_div h5{
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
    padding-top: 9px;
    line-height: 23px;
    
}

/* end */

/* our team css */


/*==================== SERVICE CARD ====================*/
.card__container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
    width: 100%;
    max-width: 100%;
    margin: 15px auto;
}
.card__bx {
    --bg-clr: #282828;
    --txt-clr: #ffffff;
    --btn-txt-clr: #333333;
    --transition: all 0.5s;
    margin-bottom: 20px;
    text-align: center;

  
    height: 400px;
    width: 300px;
    overflow: hidden;
    border-radius: 20px;
    border-top-left-radius: 70px;
    position: relative;
    overflow: hidden;
    background: var(--clr);
    transition: var(--transition);
}
.card__1 {
    --clr: #47143d;
}
.card__2 {
    --clr: #936c49;
}
.card__3 {
    --clr: #000;
}


.card__4 {
    --clr: #936c49;
}
.card__bx .card__data {
    position: absolute;
    inset: 10px;
    border-radius: 10px;
    background: var(--bg-clr);
}
.card__bx .card__data .card__icon {
    position: absolute;
    top: 0;
    left: 0;
    border-bottom-right-radius: 50%;
    height: 140px;
    width: 140px;
    background: var(--clr);
}
.card__bx .card__data .card__icon::before {
    content: '';
    position: absolute;
    bottom: -30px;
    left: 0;
    height: 30px;
    width: 30px;
    border-top-left-radius: 30px;
    background: transparent;
    box-shadow: -5px -5px 0 5px var(--clr);
}
.card__bx .card__data .card__icon::after {
    content: '';
    position: absolute;
    right: -30px;
    top: 0;
    height: 30px;
    width: 30px;
    border-top-left-radius: 30px;
    background: transparent;
    box-shadow: -5px -5px 0 5px var(--clr);
}
.card__bx .card__data .card__icon .card__icon-bx {
    position: absolute;
    inset: 10px;
    border-radius: 10px;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    background: var(--bg-clr);
    color: #fff;
}
.card__bx:hover .card__data .card__icon .card__icon-bx {
    background: var(--clr);
    color: var(--txt-clr);
    transition: var(--transition);
}
.card__bx .card__data .card__content {
    position: absolute;
    top: 150px;
    padding: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1px;
    color: var(--txt-clr);
}
.card__bx .card__data .card__content h3 {
    font-size: 20px;
    font-weight: 700;
    letter-spacing: .3px;
    text-transform: capitalize;
}
.card__bx .card__data .card__content p {
    font-size: 16px;
    opacity: 0.75;
    font-weight: 400 !important;
}
.card__bx .card__data .card__content a {
    display: inline-flex;
    align-self: center;
    padding: 10px 25px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    border-radius: 30px;
    border: 2px solid var(--clr);
    color: var(--btn-txt-clr);
    background: var(--clr);
    transition: var(--transition);
}
.card__bx .card__data .card__content a:hover {
    background: transparent;
    color: var(--clr);
}


.our_team_sec{
    padding-top: 10px;
    padding-bottom: 20px;
}

.our_team_txt_div{
    margin-bottom: 20px;
}

.our_team_txt_div h3{
    text-transform: capitalize;
    font-size: 35px;
    font-weight: 700;
  
}

.our_team_txt_div p{
    font-size: 16px;
    line-height: 28px;
    font-weight: 500;
}

/* our team end */

/*  */
.center_img_sec img{
    width: 100%;
    margin-bottom: 20px;
}
/*  */

/*  */
.sustaina_sec{
    padding-top:40px;
      padding-bottom: 40px;
    background-color: #fafafa;
  }
  
  .sustain_txt_div h3{
    font-weight: 600;
    font-size: 23px;
    color: var(--primary-color);
  }
  
  .sustain_txt_div p{
    color: var(--black-color3);
    font-size: 16px;
    line-height: 26px;
  }
  
  .sustain_img_box_txt{
    background-color: #fff;
    padding: 15px 15px 15px 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  .sustain_img_box_txt h4{
    font-size: 18px;
    padding-top: 6px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 25px;
  }
  
  .sustain_img_box_txt p{
    color: var(--black-color3);
    font-size: 16px;
    line-height: 26px;
  }
  
  .sustain_img_box{
    margin-top: 13px;
    text-align: center;
    background-color: #fff;
    padding: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  
  /*  */

  /* =====================products================= */
.product_section{
	padding: 20px 0px;
}
.product_haed_ti {
	text-align: center;
	padding-bottom: 5px;
}

.product_haed_ti  h3{
	text-transform: capitalize;
	font-size: 35px;
	font-weight: 700;
    color: var(--primary-color);
}

.product_haed_ti p{
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
}

.products_main_div {
	padding: 6px;
	background-color: #fff;
	margin-bottom: 20px;
	width: 100%;
	border-radius: 2px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	border: 5px solid #fff;
}

.products_img_box {
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
	border-radius: 2px;
}

.products_img_box img{
	display: block;
	margin: auto;
	width: 100%;
	
	object-fit: cover;
	border-radius: 2px;
}

.products_main_div h4{
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 700;
	color: var(--black-color3);
    padding-top: 10px;
}

.products_main_div p{
	font-size: 16px;
	color: var(--black-color2);
	line-height: 26px;
    font-weight: 500;
}

.product_btn_div{
	margin-bottom: 10px;
    padding-top: 10px;
}
.product_btn_div a{
	background-color: var(--primary-color);
	color: #fff;
	padding: 10px 20px 10px 20px;
	text-transform: capitalize;
	font-size: 14px;
	letter-spacing: 1px;
}

.product_btn_div:hover a{
	background-color: var(--third-color);
}
/* ============end============== */

/*  */
.why_sectin_md{
    background-image: url('../banner/4.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.why_md_ovr{
    background-color: rgb(0 0 0 / 38%);
    padding: 30px 0px;  
}

.why_md_txt_div{
    background-color: var(--white-color);
    padding: 60px 40px;
    background-image: url("https://img.freepik.com/free-vector/gray-background-vector-with-hexagons_53876-136769.jpg?size=626&ext=jpg&ga=GA1.1.1369675164.1715299200&semt=ais");
    animation: mover 1s infinite  alternate;
}

.why_md_txt_div h3{
    color: var(--primary-color);
    text-transform: capitalize;
    font-weight: 700;
    font-size: 35px;
    padding-bottom: 10px;
}

.why_md_txt_div  p{
    line-height: 30px;
    font-size: 16px;
    font-weight: 600;
}

.why_mdl_btn{
    text-align: center;
    margin-top: 25px;
}

.why_mdl_btn a{
    background-color: var(--four-color);
    padding: 15px 30px 15px 30px;
    color: var(--white-color);
    text-transform: capitalize;
    transition: .5s ease-in-out;
}

.why_mdl_btn a:hover{
    background-color: var(--primary-color);
}

/*  */

/* counter section */

.counter_section{
    background-image: url('https://images.pexels.com/photos/949587/pexels-photo-949587.jpeg?cs=srgb&dl=pexels-rovenimages-com-344613-949587.jpg&fm=jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
}

.counter_section_ovr{
    background-color: #db0707cc;
    padding-top: 30px;
    padding-bottom: 10px;
}

.main_div_counter{
    text-align: center;
    background-color: var(--white-color);
    padding: 50px 10px;
    background-image: url("https://img.freepik.com/free-vector/gray-background-vector-with-hexagons_53876-136769.jpg?size=626&ext=jpg&ga=GA1.1.1369675164.1715299200&semt=ais");
    margin-bottom: 20px;
   
}

.main_div_counter2{
    animation: mover 1s infinite  alternate;
}

.counter_icon_div {
    margin-bottom: 10px;
}
.counter_icon_div i{
    background-color: var(--primary-color);
    width: 90px;
    height: 90px;
    line-height: 90px;
    color: var(--white-color);
    border-radius: 100px;
    font-size: 40px;
    border: 4px solid var(--white-color);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    outline: 4px solid var(--primary-color);
}


.count_run_div .count{
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase !important;
}

.count_run_div .plus{
    font-size: 35px !important;
}

.main_div_counter h5{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    padding-top: 10px;
    color: var(--primary-color);
}

/* counter end */

/* pagetitle css */
.page_title_mkp_section {
    /* background-image: url('https://media.istockphoto.com/id/1623303770/photo/creative-background-image-is-blurred-evening-city-lights-and-light-snowfall.webp?b=1&s=170667a&w=0&k=20&c=PhWd1zt98e6K70KAAb1BjzZXGdQZZRwfqvdtJOwTfIw='); */
    position: relative;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    background-color: red;
  }
  
  .page_ti_overly_mkp_div {
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 20px;
  }
  
  .page_title_tax_mkp_main_div {
    text-align: center;
    margin: auto;
  }
  
  .page_title_tax_mkp_div {
    text-align: center;
    display: inline-block;
    padding: 7px 5px 0px 5px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  
  .page_title_tax_mkp_div h1 {
    color: var(--primary-color);
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 600;
  }

  .page_title_tax_mkp_div h1 img{
    width: 20px;
    text-align: center;
    align-items: center;
    margin-top: -12px;
    animation: mover 1s infinite  alternate;
  }
  
  .page_title_tax_mkp_div ul {
    list-style: none;
    padding: 0px 6px;
    margin-top: -7px;
  }
  
  .page_title_tax_mkp_div li a {
    color: var(--black-color);
    font-weight: 700;
  }
  
  .page_title_tax_mkp_div li {
    display: inline-block;
    margin: 0px 10px 0px 0px;
    color: var(--secondry-color);
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 700;
  }
  
  .page_title_tax_mkp_div li:before {
    position: relative;
    content: "\2059";
    margin-right: 4px;
    font-weight: bold;
    color: var(--secondry-color);
    top: -1px;
    font-size: 20px;
  }
  
  /* page titile css end */

  /* =================segement css */

.our_mostpro_section{
    padding: 10px 0px;
    background-image: url("https://img.freepik.com/free-vector/gray-background-vector-with-hexagons_53876-136769.jpg?size=626&ext=jpg&ga=GA1.1.1369675164.1715299200&semt=ais");
    margin: 0px 0px;
}

.mostpro_haed_title {
    text-align: center;
    margin-bottom: 40px;
}

.mostpro_haed_title h3 {
    color: var(--primary-color);
    text-transform: capitalize;
    font-size: 43px;
    font-weight: 700;
    letter-spacing: .4px;
}

.mostpro_haed_title h3 span{
    color: var(--secondry-color);
}

.mostpro_haed_title p {
    color: var(--black-color4);
    font-size: 17px;
    font-weight: 500;
    letter-spacing: .3px;

}

.mostpro_main_item{
    position: relative;
    overflow: hidden;
    transition: .5s ease-in-out;
    text-align: center;
    margin-bottom: 10px;
    background-color: #fff;
    padding: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.mostpro_img_div_item{
    text-align: center;
}


.mostpro_img_div_item img{
    display: block;
    margin: auto;
     width: 100%;
    filter: brightness(90%);
    transition: .4s ease-in-out;
}

.mostpro_img_div_item:hover img {
    filter: brightness(100%);
    transition: .4s ease-in-out;
}

.overlay_segement_txt_div{
    position: absolute;
    bottom: 1%;
    left: 1%;
    opacity: 0;
}

.overlay_segement_txt_div h4{
    background-color: var(--white-color);
    padding: 15px 70px 15px 40px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    text-transform: capitalize;
    font-size: 20px;
    color: var(--secondry-color);
    font-weight: 600;
}

.owl-theme .owl-dots .owl-dot span{
    background-color: var(--primary-color);
}


.our_mostpro_section .owl-prev {
    font-size: 14px !important;
    box-shadow: none !important;
    background-color: var(--black-color3) !important;
    color: var(--white-color) !important;
    outline: none !important;
    width: 35px !important;
    height: 35px !important;
    line-height: 32px !important;
    text-align: center !important;
    border-radius: 100% !important;


}

.our_mostpro_section .owl-next {
    font-size: 14px !important;
    box-shadow: none !important;
    background-color: var(--black-color3) !important;
    color: var(--white-color) !important;
    outline: none !important;
    width: 35px !important;
        height: 35px !important;
        line-height: 32px !important;
    text-align: center !important;

    border-radius: 100% !important;
} 

/* end=========== */

  
  /* ==============contact us page css start=============================*/
.contact_us_page_section{
    padding-top: 30px;
    /* padding-bottom: 10px; */
}


.contact_page_title_div{
    text-align: center;
    padding-bottom: 34px;
}

.contact_page_title_div h2{
    color: var(--black-color4);
    font-weight: 700;
    font-size: 35px;
    text-transform: capitalize;
}

.main_div_contactus_page  h2{
    color: var(--black-color4);
    font-weight: 700;
    font-size: 25px;
    text-transform: capitalize;
    padding-bottom: 10px;
}


.contact_page_form  h2{
    color: var(--black-color4);
    font-weight: 700;
    font-size: 25px;
    text-transform: capitalize;
    padding-bottom: 10px;
}

.contact_page_title_div h2 span{
    color: var(--secondry-color);
}

.main_div_contactus_page{
    margin-bottom: 20px;
}

.contact_us_flx_div_main{
    margin-bottom: 15px;
}

.contact_us_flx_div_main .d-flex .flex-shrink-0 i{
    background-color: var(--secondry-color);
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: var(--white-color);
    border-radius: 100px;
    font-size: 20px;
}

.contact_us_flx_div_main .contact_page_num_div b{
    color: var(--secondry-color);
    font-size: 17px;
    text-transform: capitalize;
}

.contact_us_flx_div_main .contact_page_num_div a{
  display: block;
  color: var(--black-color5);
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;

}

.contact_us_flx_div_main .contact_page_num_div p {
    color: var(--black-color5);
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
}



.contact_page_form .input-group ::placeholder {
    font-size: 14px;
    font-weight: 500;
}

.contact_page_form .input-group input,
textarea {
    margin-bottom: 0px;
    box-shadow: none !important;
    border-radius: 0px !important;
    outline: none !important;
    padding: 10px 0px 10px 10px;
}

.contact_page_form .input-group-text {
    margin-bottom: 0px;
    box-shadow: none !important;
    border-radius: 0px !important;
    outline: none !important;
    background-color: transparent !important;
    padding-top: 13px;
    color: var(--black-color);
}

.contact_page_form select {
    margin-bottom: 0px;
    box-shadow: none !important;
    border-radius: 0px !important;
    outline: none !important;
    padding: 11px 0px 11px 10px;
}

.contact_page_form select option {
    font-size: 15px !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    font-weight: 600;

}


.contact_page_form select {
    font-size: 14px !important;
    text-transform: capitalize;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 600;


}

.contact_page_form .input_div input.btn {
    background-color: var(--black-color);
    padding: 10px 20px 10px 20px;
    color: var(--white-color);
    border-radius: 0px;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: .5px;
    margin-bottom: 10px;

}

.contact_page_form .input_div input.btn:hover {
    background-color: var(--secondry-color);
}
/* ==============contact us page css end =============================*/


/*  */
.epr_page_sec{
    padding-top: 60px;
      padding-bottom: 50px;
  }
  
  .epr_pg_txt_div{
    margin-bottom: 15px;
  }
  
  .epr_pg_txt_div h2{
    text-transform: capitalize;
    font-weight: 600;
    font-size: 22px;
  }

  .epr_pg_txt_div h3{
   padding-top: 10px;
   padding-bottom: 10px;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 20px;
  }
  
  .epr_pg_txt_div b{
    color: var(--primary-color);
    font-size: 16px;
  }
  
  .epr_pg_txt_div strong {
    color: #212121;
    font-size: 14px;
  }
  
  .epr_pg_txt_div p{
    color: var(--black-color3);
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
  }
  
  .epr_imgpage_box{
    text-align: center;
    margin-top: auto;
  }
  
  .epr_pg_txt_div2{
    margin-top: 30px;
  }
  
  .epr_pg_txt_div2 p {
    color: var(--black-color3);
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
  }
  
  .epr_pg_txt_div2 h3 {
    text-transform: capitalize;
    font-weight: 600;
    font-size: 17px;
  }
  
  .bottom_txt{
      color: var(--primary-color);
    font-size: 16px;
    line-height: 27px;
    margin-bottom:20px;
    font-weight:bold;
  }
  
  .bottom_str{
      font-size:18px;
        font-weight:bold;
      
            
  }
  
  /*  */


  /* about us css start */
.inner_page_dt_section{
    padding: 50px 0;
    position: relative;
}

.sec_img_overlay{
    position: absolute;
    right: 17%;
    bottom: 7%;
    z-index: -99999;
    opacity: .6;
}

.sec_img_overlay2{
    position: absolute;
    right: 0;
    bottom: 6%;
    z-index: -99999;
    opacity: .6;
}

.sec_img_overlay2 img{
    width: 250px;
}


.inner_page_dt_img_main_div{
    position: relative;
    margin-bottom: 20px;
    transition: .5s ease-in-out;
    text-align: center;
    width: 100%;
}

.inner_page_dt_img_box{
    position: relative;
    transition: .5s ease-in-out;
    text-align: center;
    width: 100%;
}

.inner_page_dt_img_box img{
    transition: .5s ease-in-out;
    text-align: center;
    width: 100%;
    margin: auto;
    border-radius: 10px;
    background-color: var(--white-color);
    padding: 6px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.inner_page_dt_overlay_box{
    position: absolute;
    top: 50%;
    left: 30%;
}

.inner_page_dt_overlay_box img{
    width: 200px;
    background-color: var(--secondry-color);
    padding: 6px;
    animation: mover 1s infinite  alternate;
    border-radius: 0px !important;
}

.inner_page_dt_content_div h2{
    text-transform: capitalize;
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    color: var(--black-color);
    line-height: 30px;
}

.inner_page_dt_content_div h4{
    text-transform: capitalize;
    font-size: 22px;
    line-height: 30px;
    font-weight: 800;
    color: var(--secondry-color); 
    padding-top: 5px;
}

.inner_page_dt_content_div p{
    font-size: 16px;
    line-height: 29px;
    font-weight: 500;
    
}


/* about us css end */
  


/* footer css */

.footer{
    position: relative;
   
  }
  
  .footer::before {
    content: "";
    position: absolute;
    background: var(--white-color);
    left: 0;
    top: 0;
    width: 100%;
    height: 6px;
  }
  
  .footer::after {
    content: "";
    position: absolute;
    background: var(--third-color);
    left: 30%;
    right: 30%;
    top: 0;
    height: 6px;
  }

.footer_overlay{
    /* background-color: rgba(4, 104, 12, 0.5); */
   padding-top: 40px;
   padding-bottom: 10px;
   background-color: var(--primary-color);
}

.footer_about_div{
    margin-bottom: 20px;
}

.footer_logo_div{
    margin-bottom: 10px;
   
}

.footer_logo_div img{
    width: 220px;
    margin: auto;
}

.footer_about_div p{
    line-height: 27px;
    font-size: 15px;
    font-weight: 600;
    color: var(--white-color);
}

.footer_about_div p a{
    color: var(--secondry-color);
}

.makeindia{
    text-align: center; 
    display: inline-block;
    margin-bottom: 20px;
}


.makeindia img{
    width: 110px;
    margin: auto;
    display: inline-block;
    filter: invert(100%);
}

.footer_headti{
    margin-bottom: 20px;
    position: relative;
}

.footer_headti h5{
    position: relative;
    font-weight: 800;
    text-transform: capitalize;
    font-size: 19px;
    padding-bottom: 10px;
    padding-top: 10px;
    color: var(--secondry-color);
}

.footer_headti h5 img{
    width: 20px;
    text-align: center;
    align-items: center;
    margin-top: -12px;
    animation: mover 1s infinite  alternate;
}

.footer_headti ul{
    list-style: none;
    padding: 0px 12px;
}

.footer_headti li{
   color: var(--white-color);
    font-size: 16px;
    border-bottom: 1px dashed var(--gray-color);
    padding: 8px 0px;
   

}

.footer_headti li a{
    color: var(--background-color);
    transition: .4s ease-in-out;
    font-weight: 500;
    font-size: 14px;
}


.footer_headti li a:hover{
    color: var(--white-color);
    transition: .4s ease-in-out;
    margin-left: 4px;
}

.footer_headti .d-flex{
    margin-bottom: 10px;
}

.footer_headti .flex-shrink-0 .bx{
    font-size: 25px;
    color: var(--secondry-color);
}

.footer_headti .footer_num_div p{
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    color: var(--white-color);
}

.footer_headti .footer_num_div a{
    display: block;
    font-size: 15px;
    font-weight: 600;
    line-height: 27px;
    color: var(--white-color);
}

.footer_icon_div a{
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-size: 14px;
    text-align: center;
    margin-top: -10px;
    
}

.footer_icon_div .bxl-instagram{
    width: 25px;
    height: 25px;
    line-height: 25px;
    background-color: var(--secondry-color);
    color: var(--white-color);
    font-size: 14px;
    
}

.footer_icon_div b{
  font-weight: 600;
  margin-right: 17px;
  font-size: 20px;
}

/* footer css end */

 /* ======================copyright section start============*/
 .copyright_section{
    background-color: var(--secondry-color);
     }

     .copyright_section p{
        text-align: center;
        padding-top: 10px;
        text-transform: capitalize;
        font-size: 15px;
        font-weight: 500;
        color: var(--white-color);
     }
   
     .copyright_section p a{
        color: var(--primary-color);
        font-weight: 800;
     }
 /* ======================copyright section end============ */

 .slider_section2{
    display: none;
 }