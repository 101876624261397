.pro_details_section{
    background-color: rgba(211, 211, 211,.4);
    padding-top: 30px;
    /* padding-bottom: 50px; */
   
  }
  
  .prodistext{
      overflow-x: auto !important;
  }
  
  .prodistext h3{
  background-color:#C72127;
  color:white;
  text-align:center;
  padding:5px;
  border-radius: 3px;  
  text-transform: capitalize; 
  font-size: 22px; 
  font-weight: 600; 
  }
  
  .prodistext b{
  color:#C72127; 
  text-transform: uppercase; 
  }
  
  .prodistext tr th{
      color: #252525;
      text-transform: capitalize;
      text-align: center;
      font-weight: 600;
  }
  .prodistext tr td{
      color: #252525;
      text-transform: capitalize;
      text-align: center;
      font-weight: 400;
      font-size: 15px;
  }
  
  
  .prodistext th a{
      color: #fff;
      font-size: 15px;
      text-transform: capitalize;
  }
  
  
  .disimg{
    text-align: center;
    margin-bottom: 20px;
    border-radius: 4px;
    position: relative;
  }
  
  .disimg img{
    filter: brightness(90%);
    /* text-transform: ; */
    text-align: center;
    margin: auto;
    border-radius: 4px;
  }
  
  .overlay_dis{
      position: absolute;
      top: 50%;
      left: 0;
  }
  
  
  .overlay_dis a{
      writing-mode: vertical-lr;
      background-color: #252525;
      color: #fff;
      padding:20px 8px 20px 8px;
      border-radius: 2px;
      margin-top: -66px;
  }
  
  
  .overlay_dis2{
      position: absolute;
      top: 30%;
      left: 0;
  }
  
  
  
  .overlay_dis2 a{
      writing-mode: vertical-lr;
      background-color: #252525;
      color: #fff;
      padding:8px;
      border-radius: 2px;
      font-size: 15px;
  }
  