.recent .text {
    padding: 30px;
  
  }
  .recent .category span {
    padding: 4px 15px;
    border-radius: 2px;
    font-weight: 600;
    font-size: 20px;
    display: inline-block;
    /* border: 1px solid #000;  */
    border-radius: 10px; 
  }
  .recent .category i {
    font-size: 20px;
    color: rgb(240, 54, 55);
    
  }
.box {
    background: none !important; /* Remove background color */
    color: #062f81 !important;
    margin: 7px; /* Add a 10px gap around each box */
}
  .recent p {
    color: #000000;
    font-size: 15px;
    
  }
  .recent .button {
    border-top: 1px solid #1447d6;
    padding: 10px 30px;
    
  }
  .recent .img{
    width: 100%;
      height: 100%;
      cursor: pointer;
      object-fit: cover;
  }
  @media (max-width: 768px) {
    .mobile-view {
      display: flex;
      flex-direction: column;
    }
    .mobile-view .box {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  .box {
    background: none !important; /* Remove background color */
    color: rgb(40, 39, 39) !important;
  }
  
  .text {
    font-size: 0.8em; /* Make text size smaller */
  }
  