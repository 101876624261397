.Certificates_head h3{
    text-align: center;
    /* padding-bottom: 20px; */
    padding-top: 20px;
        color: #d00701;

}

.Certificates_txt{
    margin-bottom: 40px;
    background-color: #d00701;
    padding: 5px;
    border-radius: 4px;
}

.Certificates_txt h3{
   /* padding-bottom: 30px;
    padding-top: 10px;*/
    color: #fff;
    text-align: center;
    font-size: 16px;
  
}